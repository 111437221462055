import * as React from "react";
// import { Container, Row, Col} from "react-bootstrap";
import GroupBrands from "../../images/group-brands.png";
import axios from "axios"
import {  DOMAIN_API,PARAM_ID, translate } from "../../utils"
import { Image } from "react-bootstrap";
 
const OurPartners = ({item}) => {
  return (  
    (item.id!==6 && item.id!==1)?
      <div>      
        <a href={`/partners?${PARAM_ID}=${item.id}`} >{(item.avatarImg)?<img src={`${DOMAIN_API()}/${item.avatarImg}`} />:<div className="  p-2   text-center rnavatar">{item.name}</div>}</a>   
      </div>
     :"" 
  )
}; 

export default OurPartners;
